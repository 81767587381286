import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/core/service/congif.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

//new 
// rout:any
// storeData(data:any){
// this.rout = data
// }
//




  FilterDownloadFile: any;
  constructor(
    private http: HttpClient, private config: ConfigService
  ) { }
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }

  get1(url: string, options: any): Observable<any> {
    return this.http.get(url, options);
  }




  Invoice(data){
    return this.http.post(`${environment.apiUrl}invoice/invoie-number-checking`,(data))
  }
  OfficeNumbr(data){
    return this.http.post(`${environment.apiUrl}invoice/office-reference-number-checking`,(data))
  }
  
  employeeGet(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }

  // Overview(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //   return this.http.get(`${environment.apiUrl}${path}`, { params });
  // }
  Overview(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  fileUpload(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`, body
    );
  }
  
  getdep(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let portalType = this.config.portalType;
    params = Object.assign(params, { portalType });
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }

  getdep1(path: string, body: Object = {}): Observable<any> {   
    return this.http.post(`${environment.apiUrl}${path}`,body);
  }

  downloadFile(path: string, body: Object = {}): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiUrl}${path}`,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }


  downloadExcel(): Observable<HttpResponse<Blob>>{
   return this.http.get( `${environment.apiUrl}employeeNew/download`, { responseType: 'blob', observe: 'response' })
  // const data ={
  //   "districtId":2,
	// "branchId":["1","2","4"],
	// "designationId":["1","2"],
	// "month":6,
	// "year":2024,
	// "portalType":"phs",
	// "customerId":"1",
	//private int serviceChargeType;
//	private int uanType;
	// "searchWith":""
 
// }
  // return this.http.get( `${environment.apiUrl}attendance/export-excel`, { responseType: 'blob' })
  }

  downloadExcelAll(){
    return this.http.get( `${environment.apiUrl}unitemployee/download-unit-excel-header`, { responseType: 'blob' as 'json' })
  }

  downloadExcelUnit(obj){

   return this.http.get( `${environment.apiUrl}unitemployee/download-unit-excel-header-data?portalType=${this.config.portalType}&districtId=${obj.districtId}&branchId=${obj.branchId}`, { responseType: 'blob' })
  }
  
  downloadExcelUnit1(obj): Observable<HttpResponse<Blob>>{

    // return this.http.get( `${environment.apiUrl}unitemployee/download-excel-header-data?portalType=${this.config.portalType}&districtId=${obj.districtId}&branchId=${obj.branchId}`, { responseType: 'blob' })
    return this.http.post( `${environment.apiUrl}employeeNew/export-excel`, obj, { responseType: 'blob' , observe:'response' })
  }

   attendanceDownloadExcelUnit1(obj): Observable<Blob> {
    return this.http.post( `${environment.apiUrl}attendance/api/EpfExport-new`, obj, { responseType: 'blob' });
   }

   attendanceDownloadExcelHobill(obj): Observable<Blob> {
    let portalType = this.config.portalType;;
    obj = Object.assign(obj, { portalType });
    return this.http.post( `${environment.apiUrl}hobill/api/EpfExport-new`, obj, { responseType: 'blob' });
   }

   newHollExcel(obj): Observable<Blob> {
    let portalType = this.config.portalType;;
    obj = Object.assign(obj, { portalType });
    return this.http.post( `${environment.apiUrl}hobillNew/get-excel-new`, obj, { responseType: 'blob' });
   }
  //  http://192.168.1.21:8016/paramount/hobillNew/get-excel-new
   attendanceDownloadExcelSalaryReport(obj): Observable<Blob> {
    let portalType = this.config.portalType;;
    obj = Object.assign(obj, { portalType });
    return this.http.post( `${environment.apiUrl}attendance/api/EpfExport-new/salary`, obj, { responseType: 'blob' });
   }

  downloadMonthly(path: string, body: Object = {}): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}${path}`, body,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  downloadHobill(path: string, body: Object = {}): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}${path}`, body,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  getjob(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getqual(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getspec(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  updatespec(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  getnationl(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getblood(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getreligion(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getcountry(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getlayout(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getholiday(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  forgot(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  forgotpass(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }

  update(path: string, body: Object = {}): Observable<any> {
    console.log(`${environment.apiUrl}${path}`,
      body)
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }

  deleteDep(path: string, body: Object = {}): Observable<any> {

    console.log(`${environment.apiUrl}${path}`, body)
    return this.http.post(
      `${environment.apiUrl}${path}`, body
    );
  }
  deleteFile(path: string, body: Object = {}): Observable<any> {

    console.log(`${environment.apiUrl}${path}`, body)
    return this.http.post(
      `${environment.apiUrl}${path}`, body
    );
  }
  //  deleteFile(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.post(`${environment.apiUrl}${path}`, { params });
  //   }
  posts(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  postsemp1(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }

  postsemp(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }


  Update(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  Updates(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  statuss(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  postqual(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  postspec(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  postjob(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  deleteJob(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`,
      body);
  }
  updatejob(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  delete(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, body);
  }
  Status(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  status(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  deletespec(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, body);
  }
  updatespeci(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    )
  }
  deletequal(path: string, body: Object = {}): Observable<any> {

    return this.http.post(`${environment.apiUrl}${path}`, body);
  }
  updatequal(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    );
  }
  //Report Api
  getReport(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getTimeReport(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getIndividualTimeReport(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params });
  }
  getMonthlyTimeReport(path: string, body: Object = {}): Observable<any> {
    console.log(`${environment.apiUrl}${path}`, body)
    return this.http.post(`${environment.apiUrl}${path}`, body);
  }
  deviceget(path: string, body: Object = {}): Observable<any> {
    console.log(`${environment.apiUrl}${path}`, body)
    return this.http.post(`${environment.apiUrl}${path}`, body);
  }
  ManualAttendance(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, body);
  }
}
