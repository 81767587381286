import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { serviceProvider } from './service/service';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [serviceProvider, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  },]
})
export class CoreModule { }
