<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 class="example-app-name">Responsive App</h1>
    </mat-toolbar>
  
    <mat-sidenav-container class="example-sidenav-container"
                           [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                   [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
        <mat-nav-list>
          <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a>
        </mat-nav-list>
      </mat-sidenav>
  
      <mat-sidenav-content>
        <p *ngFor="let content of fillerContent">{{content}}</p>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>