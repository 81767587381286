import { MenuItem } from "./navigation.model";
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";

export let UserList: MenuItem[] = [
  {
    module: ["overview"],
    name: "Overview",
    type: "link",
    tooltip: "Overview",
    state: "/dashboard",

    
  },
  {
    module: ["employee_registration"],
    name: "Employees",
    type: "link",
    tooltip: "Employees",
    state: "/employee/list",
  },
  // {
  //   module: ["clients"],
  //   name: "Clients",
  //   type: "link",
  //   tooltip: "Clients",
  //   state: "/client-list",
  // },
  // {
  //   module: ["emp_manual_attendance"],
  //   name: "Attendance",
  //   type: "link",
  //   tooltip: "Attendance",
  //   state: "/employee/manualattendance",
  // },
  // {
  //   module: ["salary", "employee-assign"],
  //   name: "Reports",
  //   type: "dropDown",
  //   tooltip: "Employees",
  //   state: "",
  //   sub: [
  //     {
  //       module: ["salary"],
  //       name: "Salary",
  //       state: "/salary",
  //     },
  //     {
  //       module: ["employee-assign"],
  //       name: "Employee-Assign",
  //       state: "/employee-assign",
  //     }
  //   ]
  // },
  // {
  //   module: [
  //     "theme_setting",
  //     "shift_time_table",
  //     "emp_department",
  //     "emp_position",
  //     "emp_qulaification",
  //     "speciality",
  //     "holiday_setting",
  //     "infastructure",
  //     'emp_attendance_setting',
  //     'stu_attendance_setting',
  //     "holiday_setting",
  //     "layout_category",
  //     "layout_position",
  //     "rule_engine",
  //     "web_setting"
  //   ],
  //   name: "Employee-Settings",
  //   type: "dropDown",
  //   tooltip: "Configuration",
  //   state: "",
  //   sub: [
  //     {
  //       module: ["shift_time_table", "emp_department", "emp_position", "emp_qualification", "speciality", "holiday_setting", "threshold_response_time"],
  //       name: "Employee Settings",
  //       type: "dropDown",
  //       state: "",
  //       sub: [
  //         {
  //           name: "Department",
  //           module: ["emp_department"],
  //           state: "/department/list",
  //         },
  //         {
  //           name: "Designation",
  //           module: ["emp_position"],
  //           state: "/job-position/list",
  //         },
  //         {
  //           name: "Qualification",
  //           module: ["emp_qualification"],
  //           state: "/qualification/list",
  //         },
  //         { name: "Holiday Settings", module: ['holiday_setting'], state: "/holiday-settings/list" },
  //       ],
  //     },
  //     {
  //       name: "Attendance Settings",
  //       module: ['shift_details', 'shift_assign'],
  //       type: "dropDown",
  //       state: "",
  //       sub: [
  //         {
  //           name: "Shift Details",
  //           module: ['shift_details'],
  //           state: "/attendance-settings/shift-details/list-detail",
  //         },
  //         {
  //           name: "Shift Assign",
  //           module: ['shift_assign'],
  //           state: "/attendance-settings/shift-assign/list",
  //         }
  //       ],
  //     },
  //   ]
  // },
]
export let MenuList: MenuItem[] = [
  {
    module: ["overview"],
    name: "Overview",
    type: "link",
    tooltip: "Overview",
    state: "/dashboard",
  }, 
  {
    module: ["employee_registration"],
    name: "Master",
    type: "dropDown",
    sub: [
      {
        module: ["district"],
        name: "Clients Master",
        type: "link",
        state: "/district/list",
      },
      {
        module: ["clients"],
        name: "Branch Master ",
        type: "link",
        state: "/branch/list",
      },
      {
        module: ["emp_designationNew"],
        name: "Designation Master",
        type: "link",
        state: "/designationNew/list",
      },
      {
        module: ["employee_registration"],
        name: "Employees Master",
        type: "link",
        state: "/employee/list",
      },
    ]
  },
  // {
  //   module: ["employee_registration"],
  //   name: "Employees",
  //   type: "link",
  //   tooltip: "Employees",
  //   state: "/employee/list",
  // },
  {
    module: ["emp_manual_attendance"],
    name: "Attendance",
    type: "dropDown",
    sub: [
      {
    module: ["emp_manual_attendance"],
    name: "Attendance",
    type: "link",
 
    state: "/employee/manualattendance"
      },
      {
        module: ["emp_manual_attendance"],
        name: "Invoice Attendance",
        state: "/unit/invoice-Attendance",
      },
      // },
      // {
      //   module: ["office-attendance"],
      //   name: "Office Attendance",
      //   state: "/employee/office-attendance"
      // }
    ]
  },
  
  // {
  //   module: ["clients"],
  //   name: "Branch Master ",
  //   type: "link",
  //   tooltip: "Branch",
  //   state: "/branch/list",
  // },
  // {
  //   module: ["district"],
  //   name: "Clients Master",
  //   type: "link",
  //   tooltip: "District",
  //   state: "/district/list",
  // },

  // {
  //   module: ["emp_designationNew"],
  //   name: "Designation",
  //   type: "link",
  //   tooltip: "Overview",
  //   state: "/designationNew/list",
  // },

  // {
  //   module: ["expense"],
  //   name: "Expense",
  //   type: "link",
  //   tooltip: "Expense",
  //   state: "/expense-list",
  // },
  {
    module: ["account", "expense","ho-bill", "invoice-report", "purchase", "vendors", "invoice"],
    name: "Accounts",
    type: "dropDown",
    state: "",
    sub: [
      // {
      //   module: ["account"],
      //   name: "Account Details",
      //   state: "account/account",
      // },
      // {
      //   module: ["expense"],
      //   name: "Expense",
      //   state: "expense/expense-list",
      // },
      // {
      //   module: ["invoice-report"],
      //   name: "Invoice-Generation",
      //   state: "/reports/invoice-report",
      // },
      // {
      //   module: ["purchase"],
      //   name: "Purchase",
      //   state: "/purchase/purchase-list"
      // },
      // {
      //   module: ["vendors"],
      //   name: "Vendors",
      //   state: "/vendors/vendor-list"
      // },


      {
        name: "Invoice",
        module: ['invoice'],
        type: "dropDown",
        state: "",
        sub: [
          {
            name: "With / Without GST",
            module: ['invoice'],
            state: "/invoice/with-gst",
          },
          {
            name: "Invoice Number History",
            module: ['invoice'],
            state: "/invoice/invoice-number-history",
          },
          // {
          //   name: "Unit Invoice Setting",
          //   module: ['invoice'],
          //   state: "/invoice/unit-invoice-setting",
          // },
          //   {
          //   module: ["ho-bill"],
          //   name: "Ho-Bill",
          //   state: "/reports/ho-bill",
          // },
          // {
          //   name: "Without GST",
          //   module: ['invoice'],
          //   state: "/invoice/unit-invoice-setting",
          // },
          // {
          //   name: "Co-Bank",
          //   module: ['invoice'],
          //   state: "/invoice/co-bank",
          // },
        ],
      },


      {
        name: "Ho-Bill",
        module: ['ho-bill'],
        type: "dropDown",
        state: "",
        sub: [

          {
            name: "Ho-Bill Report",
            module: ['ho-bill'],
            state: "/reports/ho-bill",
    
          },
    
    
          {
            name: "Ho-Bill Report History",
            module: ['ho-bill'],
            state: "/reports/ho-bill-list",
    
          },
        ]}

     





    ]
  },
  // {
  //   module: ["account"],
  //   name: "Account",
  //   type: "link",
  //   tooltip: "Account",
  //   state: "/account",
  // },
  {
    module: ["salary", "outstaff-report", "eroad-salary-report", "employee-assign", "esi-epf", 'loginandlogout', 'advance-salary', 'outstaff-report-history', 'trash'],
    name: "Reports",
    type: "dropDown",
    tooltip: "Employees",
    state: "",
    sub: [
      {
        module: ["file-upload"],
        name: "File-Upload",
        state: "/reports/file-upload",
      },
      // {
      //   module: ["ho-bill"],
      //   name: "Ho-Bill",
      //   state: "/reports/ho-bill",
      // },
      {
        module: ["employee-assign"],
        name: "Employee-Assign Report",
        state: "/reports/employee-assign",
      },
      {
        name: "Active Sheet",
        module: ['reports'],
        type: "dropDown",
        state: "",
        sub: [
          {
            module: ["epf-active-sheet"],
            name: "EPF Active Sheet",
            state: "/reports/epf-active-sheet",
          },
          {
            module: ["epf-active-sheet-history"],
            name: "EPF Active Sheet History",
            state: "/reports/epf-active-sheet-history",
          },
          {
            module: ["esi-active-sheet"],
            name: "ESI Active Sheet",
            state: "/reports/esi-active-sheet",
          },
          {
            module: ["esi-active-sheet-history"],
            name: "ESI Active Sheet History",
            state: "/reports/esi-active-sheet-history",
          },
        ],
      },
      {
        name: "Salary Report",
        module: ['reports'],
        // state: "/reports/outstaff-report",
        type: "dropDown",
        // state: "",
        sub: salarySubAdded()
      },
      // {
      //   module: ["esi-epf"],
      //   name: "Esi & Epf-Report",
      //   state: "/reports/esi-epf",
      // },
      // {
      //   module: ["tax-deduction"],
      //   name: "Tax Report",
      //   state: "/reports/tax-deduction",
      // },
      // {
      //   module: ["invoice"],
      //   name: "Invoice-Report",
      //   state: "/reports/invoice",
      // },
      // {
      //   module: ["loginandlogout"],
      //   name: "Login-LogOut Report",
      //   state: "/reports/loginandlogout",
      // },
      // {
      //   module: ["printout"],
      //   name: "PrintOut Report",
      //   state: "/reports/printout",
      // },
      {
        module: ["trash"],
        name: "Trash Report",
        state: "/reports/trash",
      },

      {
        module: ["activity-log-report"],
        name: "Activity Log Report",
        state: "/reports/activity-log-report",
      },
      
      {
        module: ["unit-list-report"],
        name: "Unit List Report",
        state: "/reports/unit-list-report",
      },

    ]
  },
  {
    module: [
      "theme_setting",
      "shift_time_table",
      "emp_department",
      "emp_position",
      "emp_qulaification",
      "speciality",
      "expense-settings",
      "inventory-setting",
      "holiday_setting",
      "infastructure",
      'emp_attendance_setting',
      'stu_attendance_setting',
      "holiday_setting",
      "layout_category",
      "layout_position",
      "rule_engine",
      "web_setting",
      "invoice-setting",
      "license-remainder",
      "payment-remainder",
      "division-list"
    ],
    name: "Configuration",
    type: "dropDown",
    tooltip: "Configuration",
    state: "",
    sub: [
      // {
      //   module: ["shift_time_table", "emp_department","designationNew", "emp_position", "emp_qualification", "speciality", "holiday_setting", "threshold_response_time"],
      //   name: "Employee Settings",
      //   type: "dropDown",
      //   state: "",
      //   sub: [
      //     // {
      //     //   name: "Department",
      //     //   module: ["emp_department"],
      //     //   state: "/department/list",
      //     // },
      //     // {
      //     //   name: "Designation",
      //     //   module: ["emp_designationNew"],
      //     //   state: "/designationNew/list",
      //     // },
      //     // {
      //     //   name: "Designation",
      //     //   module: ["emp_position"],
      //     //   state: "/job-position/list",
      //     // },
      //     {
      //       name: "Qualification",
      //       module: ["emp_qualification"],
      //       state: "/qualification/list",
      //     },
      //     {
      //       name: "Shift Details",
      //       module: ['shift_details'],
      //       state: "/attendance-settings/shift-details/list-detail",
      //     },
      //     // { name: "Holiday Settings", module: ['holiday_setting'], state: "/holiday-settings/list" },
      //   ],
      // },
      // {
      //   name: "Inventory Settings",
      //   module: ['inventory-setting'],
      //   type: "dropDown",
      //   state: "",
      //   sub: [
      //     {
      //       name: "Inventory-Type",
      //       module: ['inventory-type'],
      //       state: "/inventory-settings/inventory-type",
      //     },
      //     {
      //       name: "Unit-Type",
      //       module: ['unit-type'],
      //       state: "/inventory-settings/unit-type",
      //     },
      //   ],
      // },
      // {
      //   name: "Expense Settings",
      //   module: ['expense-settings'],
      //   type: "dropDown",
      //   state: "",
      //   sub: [
      //     {
      //       name: "Expense-Type",
      //       module: ['expense-type'],
      //       state: "/expense-settings/expense-type",
      //     },
      //     {
      //       name: "Transaction-Type",
      //       module: ['transaction-type'],
      //       state: "/expense-settings/transaction-type",
      //     },
      //   ],
      // },
      // {
      //   module: ["license-remainder"],
      //   name: "License-Remainder",
      //   type: "link",
      //   state: "/license-list",
      // },
      // {
      //   module: ["payment-remainder"],
      //   name: "Payment-Remainder",
      //   type: "link",
      //   state: "/payment-list",
      // },
      // {
      //   module: ["officeRef-list"],
      //   name: "Office Reference Number",
      //   type: "link",
      //   state: "/officeRef-list",
      // },
      {
        module: ["officeRef-list"],
        name: "Office Reference Number",
        type: "link",
        state: "/invoice-list",
      },
      {
        module: ["Invoice-list"],
        name: "Invoice Number Setting",
        type: "link",
        state: "/InviceNum-list",
      },

      {
        module: ["division-list"],
        name: "Division Setting",
        type: "link",
        state: "/division-list",
      },
      // {
      //   name: "Attendance Settings",
      //   module: ['shift_details'],
      //   type: "dropDown",
      //   state: "",
      //   sub: [
      //     {
      //       name: "Shift Details",
      //       module: ['shift_details'],
      //       state: "/attendance-settings/shift-details/list-detail",
      //     },
      //   ],
      // },
    ]
  },
  // {
  //   module: ["unit"],
  //   name: "Unit",
  //   type: "dropDown",
  //   state: "",
  //   sub: [
  //     {
  //       module: ["unit"],
  //       name: "Client",
  //       state: "/unit/client"
  //     },
  //     {
  //       module: ["unit"],
  //       name: "Branch",
  //       state: "/unit/branch"
  //     },
  //     {
  //       module: ["unit"],
  //       name: "Designation",
  //       state: "/unit/designation"
  //     },
  //     {
  //       module: ["unit"],
  //       name: "Employee",
  //       state: "/unit/employee"
  //     },
  //     {
  //       module: ["unit"],
  //       name: "Attendance",
  //       state: "",
  //       type: "dropDown",
  //       sub: [
  //         {
  //           name: "Unit Attendance",
  //           module: ["unit"],
  //           state: "/unit/unit-attendance",
  //         },
  //         {
  //           name: "Employee Attendance",
  //           module: ["unit"],
  //           state: "/unit/employee-attendance",
  //         },
  //       ],
  //     },
  //     {
  //       module: ["unit"],
  //       name: "Salary Report",
  //       state: "/unit/salary-report"
  //     },
  //     {
  //       module: ["unit"],
  //       name: "Invoice",
  //       state: "",
  //       type: "dropDown",
  //       sub: [
  //         {
  //           name: "Invoice Report",
  //           module: ["unit"],
  //           state: "/unit/invoice",
  //         },
  //         {
  //           name: "Invoice Excel",
  //           module: ["unit"],
  //           state: "/unit/invoice-excel",
  //         },
  //         {
  //           name: "Invoice History",
  //           module: ["unit"],
  //           state: "/unit/invoice-history",
  //         },
  //         {
  //           module: ["unit"],
  //           name: "Invoice Configuration",
  //           state: "/unit/invoice-configuration"
  //         },
  //         // {
  //         //   module: ["ho-bill"],
  //         //   name: "Ho-Bill",
  //         //   state: "/unit/reports/ho-bill",
  //         // },

  //       ]
  //     },
    

  //   ]
  // },
  // {
  //   module: ["stock-list", "stock-in", "stock-out"],
  //   name: "Inventory",
  //   type: "dropDown",
  //   state: "",
  //   sub: [
  //     {
  //       module: ["inventory"],
  //       name: "Stock",
  //       state: "/inventory/stock-list"
  //     },
  //     {
  //       module: ["inventory"],
  //       name: "Stock-Entry",
  //       state: "/inventory/stock-in"
  //     }
  //   ]
  // },
  // {
  //   module: ["quotation"],
  //   name: "Quotation",
  //   type: "link",
  //   tooltip: "Quotation",
  //   state: "quotation/quotation-list",
  // },
  
]

function salarySubAdded() {
  const token = decodedToken();
  const portalType = get(token, 'portalType') ? get(token, 'portalType') : '';
  if (portalType === 'hiversion' || portalType === "") {
    return  [ 
    {
      module: ["outstaff-report"],
      name: "Staff Report",
      state: "/reports/outstaff-report",
    },
    {
      module: ["outstaff-report-history"],
      name: "Staff Report History",
      state: "/reports/outstaff-report-history",
    },
    // {
    //   module: ["eroad-salary-report"],
    //   name: "Erode-Salary-Report",
    //   state: "/reports/eroad-salary-report",
    // },
    // {
    //   module: ["advance-salary"],
    //   name: "Advance-Salary Report",
    //   state: "/reports/advance-salary",
    // },
    // {
    //   module: ["uniform-salary"],
    //   name: "Uniform-Salary Report",
    //   state: "/reports/uniform-salary",
    // },
    // {
    //   module: ["inc-dec"],
    //   name: "Increment/Decrement",
    //   state: "/reports/inc-dec",
    // },
    ]
  } else {
    return  [ {
      module: ["outstaff-report"],
      name: "Staff Report",
      state: "/reports/outstaff-report",
    },
    {
      module: ["outstaff-report-history"],
      name: "Staff Report History",
      state: "/reports/outstaff-report-history",
    },
    // {
    //   module: ["advance-salary"],
    //   name: "Advance-Salary Report",
    //   state: "/reports/advance-salary",
    // },
    // {
    //   module: ["uniform-salary"],
    //   name: "Uniform-Salary Report",
    //   state: "/reports/uniform-salary",
    // },
    // {
    //   module: ["inc-dec"],
    //   name: "Increment/Decrement",
    //   state: "/reports/inc-dec",
    // },
    ]
  }
 
}

