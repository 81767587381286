import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'src/app/core/service/user.service';
import { ConfigService } from 'src/app/core/service/congif.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @Input() sidenav: MatSidenav
  onClick: any;
  
  constructor(private userService: UserService, private config: ConfigService) { }

  ngOnInit(): void {
  }
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        { name: 'SUBITEM', state: 'cards' },
        { name: 'SUBITEM', state: 'buttons' }
      ]
    });
  }

  Signout() {
    this.userService.logout();
    let obj = {
      "customerId": this.config.customerId,
      "districtId": this.config.districtId,
      "mail": this.config.mail,
      "password": this.config.password,
    }
    this.userService.signOut(obj).subscribe((data) => {
      if (data.statusCode == 200) {
        this.userService.logout();
      }
    })    
  }
}
