import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ExamplesModule } from 'src/assets/examples/examples.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgVerticalTimelineModule } from 'ng-vertical-timeline';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { OutStaffHistoryComponent } from './reports/out-staff-history/out-staff-history.component';
// import { InvoiceNumSettComponent } from './Modules/invoice-num-sett/invoice-num-sett.component';
// import { InvoiceCreateComponent } from './Modules/Invoice_Num_sett/invoice-create/invoice-create.component';
// import { InvoiceUpdateComponent } from './Modules/Invoice_Num_sett/invoice-update/invoice-update.component';
// import { InvoiceListComponent } from './Modules/Invoice_Num_sett/invoice-list/invoice-list.component';
// import { OfficeSettingModule } from './Modules/Configuration/module/office-ref-module';
// import { CreateOfficeReferenceNumberSettingComponent } from './Modules/Configuration/create-office-reference-number-setting/create-office-reference-number-setting.component';
// import { UpdateOfficeReferenceNumberSettingComponent } from './Modules/Configuration/update-office-reference-number-setting/update-office-reference-number-setting.component';


@NgModule({
  declarations: [
    AppComponent,
    // OutStaffHistoryComponent,
    // InvoiceNumSettComponent,
    // InvoiceCreateComponent,
    // InvoiceUpdateComponent,
    // InvoiceListComponent,
    
    // CreateOfficeReferenceNumberSettingComponent,
    // UpdateOfficeReferenceNumberSettingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    SharedModule,
    HttpClientModule,
    MatDialogModule,
    MglTimelineModule,
    NgVerticalTimelineModule,
    ExamplesModule,
    // OfficeSettingModules

















    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatCardModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSortModule,
    MatPaginatorModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
