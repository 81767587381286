// import { Injectable } from '@angular/core';
// import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { Observable } from 'rxjs';
// import { AppLoaderComponent } from 'src/app/shared/shared-component/app-loader/app-loader.component';

// @Injectable({
//   providedIn: 'root'
// })
// export class AppLoaderService {
//   dialogRef: MatDialogRef<AppLoaderComponent>;
//   constructor(private dialog: MatDialog) { }

//   public open(title: string = 'Please wait'): Observable<boolean> {
//     this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, backdropClass: 'light-backdrop'});
//     this.dialogRef.updateSize('200px');
//     // this.dialogRef.componentInstance.title = title;
//     return this.dialogRef.afterClosed();
//   }

//   public close() {
//     if(this.dialogRef)
//       this.dialogRef.close();
//   }
// }









import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoaderComponent } from 'src/app/shared/shared-component/app-loader/app-loader.component';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  private dialogRef: MatDialogRef<AppLoaderComponent> | null = null;
  private activeRequests: number = 0;

  constructor(private dialog: MatDialog) { }

  public open(title: string = 'Please wait'): void {
    this.activeRequests++;
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, backdropClass: 'light-backdrop' });
      this.dialogRef.updateSize('200px');
      // this.dialogRef.componentInstance.title = title;
    }
  }

  public close(): void {
    if (this.activeRequests > 0) {
      this.activeRequests--;
    }

    if (this.activeRequests === 0 && this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }
}

